import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import { ResourceCenter } from 'cccisd-laravel-resources';
import { Quest } from 'cccisd-laravel-assignment';

// Layouts
import MainLayout from './layouts/MainLayout';
import WelcomeLayout from './layouts/WelcomeLayout';
import QuestLayout from './layouts/QuestLayout';
import ManageLayout from './layouts/ManageLayout';
// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import DataEntry from './pages/DataEntry';
import RolePicker from './pages/RolePicker';
// import SignUp from './pages/SignUp';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Welcome,
    DataEntry,
    ResourceCenter,
    RolePicker,
    // SignUp,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
    ManageLayout,
    WelcomeLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={AuthRedirect} exact />
        <AppRoute path="/welcome" component={Welcome} layout={WelcomeLayout} />
        <AppRoute path="/account/login" component={Welcome} layout={WelcomeLayout} exact />
        {/* <AppRoute path="/signup" component={SignUp} /> */}
        {Fortress.auth() && ['guAdmin', 'guTeamMember'].includes(Fortress.user.acting.data_type) && (
            <AppRoute path="/planning" component={DataEntry} layout={MainLayoutFluid} />
        )}

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: QuestLayout }}
        />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
