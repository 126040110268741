import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import Style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + Style.wrapper}>
            <div className={Style.content}>
                <div className={`col-md-8 ${Style.titleBox}`}>
                    <div className={Style.title}>
                        <h1 style={{ fontWeight: 'bold' }}>Welcome to eGTO</h1>
                    </div>
                    <div className={Style.description}>
                        <p style={{ fontSize: 'larger' }}>
                            GTO’s goal is to help you reach <em>your</em> goal. This self-paced system walks VSOs
                            through each GTO step, offering advice and points of consideration as you go along.
                        </p>
                        <p>
                            If you have questions or would like to request free access to eGTO, please email{' '}
                            <a href="mailto:VeteransPolicy@rand.org">VeteransPolicy@rand.org</a>.
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    {Fortress.auth() ? (
                        <div className={Style.welcomeBox}>
                            <p>Hello, {Fortress.user.username}</p>
                            {homepageRoute && (
                                <Link to={homepageRoute.url}>
                                    <button type="button" className="btn btn-primary">
                                        Go To Home
                                    </button>
                                </Link>
                            )}
                        </div>
                    ) : (
                        <LoginForm showPasswordToggle />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Welcome;
